import React, {useContext, useEffect, useState} from 'react';
import Link from "@Components/Link";
import {Helmet} from "rnd-helmet";
import {injectIntl} from "react-intl";
import FormContext from "formContext";

function Homepage({intl}) {
  const {showToast, setToastMessage} = useContext(FormContext);
  useEffect(() => {
    if (window.location.hash === "#toast=logged-out") {
      setToastMessage(intl.formatMessage({id: 'Login.LoggedOut.Text'}));
      showToast();
    }
  }, []);

  // Load the routes for the specific country.
  const routesCore = require(`../../../config/core/client.routes.json`);
  const routesOpCo = require(`../../../config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
  const routes = {
    ...routesCore,
    ...routesOpCo
  };

  let apiRoutes = {};
  if (process.env.REACT_APP_ISDEV) {
    const apiRoutesCore = require(`../../../config/core/api.routes`);
    let apiRoutesOpCo = {};
    try {
      apiRoutesOpCo = require(`../../../config/${process.env.REACT_APP_COUNTRY}/api.routes`);
    }
    catch (e) {
    }
    apiRoutes = {
      ...apiRoutesCore,
      ...apiRoutesOpCo
    };
  }
  const lang = intl.locale ? intl.locale : process.env.REACT_APP_DEFAULT_LANGUAGE;

  let clientRoutesMarkup = [];
  for (let routeName in routes[lang]) {
    if (routes[lang].hasOwnProperty(routeName) && routes[lang][routeName].hasOwnProperty('url')) {
      let route = routes[lang][routeName];
      clientRoutesMarkup.push(
        <div key={`route-${lang}-${routeName}`}>
          {routeName !== 'logout' ? <Link to={route.url}>{routeName}</Link>: <a href={route.url}>{routeName}</a>}
        </div>
      );
    }
  }

  let apiRoutesMarkup = [];
  if (process.env.REACT_APP_ISDEV) {
    for (let routeName in apiRoutes) {
      apiRoutesMarkup.push(
        <div key={`server-route-${routeName}`}>
          <a href={apiRoutes[routeName].url}>{routeName}</a> - {apiRoutes[routeName].url}
        </div>
      );
    }
  }

  return (
    <div>
      <Helmet>
        <header className="header header--text bg-variant-brand-secondary"/>
      </Helmet>
      <div>
        <h2>Documentation</h2>
        <a href='/myrandstad-app/docs/api'>Documentation</a>
        <h2>React routes</h2>
        {clientRoutesMarkup}
      </div>
      {
        process.env.REACT_APP_ISDEV ?
          <div>
            <h2>API routes</h2>
            {apiRoutesMarkup}
          </div> : null
      }
    </div>
  )
}

export default injectIntl(Homepage);
